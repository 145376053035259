import img1 from './../images/Portfolio/Netflix.png';
import img2 from './../images/Portfolio/hbo-max-logo.png';
import img3 from './../images/Portfolio/react.png';
import img4 from './../images/Portfolio/react.png';
import img5 from './../images/Portfolio/react.png';
import img6 from './../images/Portfolio/lawncare.png';


const Portfolio = [
    {
        id: 1,
        category: 'Website',
        title: 'Netflix Landing Page Clone',
        subTitle: 'HTML, CSS, Jacascript',
        image: img1,
        link1: 'https://master.d1seosfjp9p6dr.amplifyapp.com/',
        
    },
    {
        id: 2,
        title: 'HBO Max Clone',
        subTitle: 'React, Next.js',
        category: 'Ecommerce',
        image: img2,
        link1: 'https://main.d1xjsk3w5nuvc6.amplifyapp.com/',
       
    },
    // {
    //     id: 3,
    //     title: 'React Project',
    //     subTitle: 'React, Carbon',
    //     category: 'Website',
    //     image: img4,
    //     link1: 'https://www.google.com/',
        
    // },
    {
        id: 4,
        title: 'Lawncare Website',
        subTitle: 'HTML, CSS, Javascript',
        category: 'Website',
        image: img6,
        link1: 'https://joeruckerslawncareservice.com/',
        
    },
    // {
    //     id: 5,
    //     category: 'Mobile App',
    //     title: 'App Development',
    //     subTitle: 'Take Your Business Online',
    //     image: img3,
    //     link1: 'https://www.google.com/',
    //     link2: 'https://www.google.com/',
    // },
    // {
    //     id: 6,
    //     title: 'Ecommerce Website',
    //     subTitle: 'Take Your Business Online',
    //     category: 'Ecommerce',
    //     image: img5,
    //     link1: 'https://www.google.com/',
    //     link2: 'https://www.google.com/',
    // }
];

export default Portfolio;
