import React from 'react';
import styled from 'styled-components';
import Title from './../components/Title';
import { InnerLayout } from './../styles/Layouts';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SchoolIcon from '@material-ui/icons/School';
import ResumeItem from './../components/ResumeItem';
import SkillsItem from './../components/SkillsItem';
function ResumePage() {
    return (
        <ResumePageStyled>
            {/* Resume Section Start Here */}
            <Title title={'Resume'} span={''} />
            <InnerLayout className="resumeSection">
                <div className="education">
                    <h4><SchoolIcon /><span>Education</span></h4>
                    <ResumeItem year={'2019'} title={'Full-Stack Coding BootCamp'} subTitle={'Tech Talent South '} text={''} />
                    {/* <ResumeItem year={'2008-2010'} title={'Bachleor Degree'} subTitle={'Kingdom University'} text={'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Velit, quisquam!'} />
                    <ResumeItem year={'2012-2015'} title={'Master Degree'} subTitle={'Hardvard Uiversity'} text={'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Velit, quisquam!'} /> */}
                </div>
                <div className="experience">
                    <h4> <BusinessCenterIcon /><span>Experience</span></h4>
                    <ResumeItem year={'2021-Present'} title={'Full-Stack Developer'} subTitle={'IBM'} text={'Work closely with app development team including project and product manager, developers, and QA to determine problems, testing methods and best practices.Developed full-stack applications across various platforms using latest industry-adopted technologies and frameworks. Conducted unit and load testing for high profile customer facing applications. Ensure applications security and ability to interact with multiple APIs and databases. Host and moderate monthly operations meetings. Created documentation for team use also for new developers. Attend various tech conferences and conduct lectures on what ive learned. Troubleshooted issues affecting production, stage, and development environments as requested.'} />
                    <ResumeItem year={'2020-2021'} title={'Microsft Azure Associate Intern'} subTitle={'Biogen'} text={'Collaborated with Internal/External Stakeholders, Development Architects/Managers & developers, across locations, to ensure timely delivery. Participate in all meetings and work with other peers on the infrastructure team to successfully provide world class support to our Customers Gather, review, and validate network requirements, VNETs, and network security groups. Deploy and configure network and firewall configurations'} />
                    <ResumeItem year={'2019-Present'} title={'Full-Stack Developer'} subTitle={'Freelance'} text={'Ability to produce quality work products with attention to detail. Handling all verbal and written communication between hosting companies and clients. Writes clean, readable and modular code. Basic knowledge and understanding of web servers,  and analytics. Works closely with clients to design, test, troubleshoot and support interfaces. Builds  and implements  the front-end using HTML5, CSS3, and Javascript. Ability to learn new programing languages'} />
                </div>
            </InnerLayout>
            {/* Resume Section End Here */}

            {/* Skills Section Start Here */}
            {/* <div className="my-skills">
                <Title title={'My'} span={'Skills'} />
                <InnerLayout>
                    <div className="skills">
                       <SkillsItem title={'HTML5'} width={'90%'} text={'90%'} />
                       <SkillsItem title={'CSS3'} width={'80%'} text={'80%'} />
                       <SkillsItem title={'JAVASCRIPT'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'BOOTSTRAP'} width={'65%'} text={'65%'} />
                       <SkillsItem title={'REACT JS'} width={'85%'} text={'85%'} />
                       <SkillsItem title={'NODE JS'} width={'75%'} text={'75%'} />
                       <SkillsItem title={'MONGO DB'} width={'70%'} text={'70%'} />
                       <SkillsItem title={'EXPRESS JS'} width={'65%'} text={'65%'} />
                       <SkillsItem title={'REACT NATIVE'} width={'85%'} text={'85%'} />
                    </div>
                </InnerLayout>
            </div> */}
            {/* Skills Section End Here */}
        </ResumePageStyled>
    )
}

const ResumePageStyled = styled.div`
    .resumeSection{
        display:grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap:3rem;
        @media screen and (max-width:992px){
            grid-template-columns: repeat(1, 1fr);
        }
        .education, .experience{
            h4{
                padding: 1rem 0;
                font-size:1.8rem;
                @media screen and (max-width:600px){
                    font-size:1.5rem;
                }
                span{
                    margin-left:.5rem;
                }
            }
            svg{
                color:var(--primary-color);
                font-size:1.8rem;
            }
        }
    }

    .skills{
        display: grid;
        grid-template-columns:repeat(2, 1fr); 
        grid-gap:1.5rem;
        margin-top: 5rem;
        @media screen and (max-width:768px){
            grid-template-columns:repeat(1, 1fr); 
        }
    }
`;

export default ResumePage;
