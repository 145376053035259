 import Blog1 from './../images/Blog/blog.jpg';
 import Blog2 from './../images/Blog/blog.jpg';
 import Blog3 from './../images/Blog/blog.jpg';
 import Blog4 from './../images/Blog/blog.jpg';

const Blogs = [
    {
        id:1,
        title:'How to earn money online',
        date:'29',
        month:'June',
        image:Blog1
    },
    {
        id:2,
        title:'How to make app with react native',
        date:'30',
        month:'June',
        image:Blog2
    },
    {
        id:3,
        title:'How to be an entrepreneur',
        date:'1',
        month:'July',
        image:Blog3
    },
    {
        id:4,
        title:'How to start freelancing',
        date:'5',
        month:'july',
        image:Blog4
    }
];

export default Blogs;
